<!-- 微信网页 js 授权 回调 -->
<template>
  <div v-loading="aloading" class="contnet">
  </div>
</template>

<script>
import { getSnsUserInfo, InsetBind } from '@/api/login.js'

export default {
  data() {
    return {
      show_msg: window.location.href,
      redirect_url: decodeURIComponent(this.$route.query.jump_redirect || ''),
      auth_code: this.$route.query.code,
      openid: '', // 通过后台交换code获取openid
      unionid: '', // 通过后台交换code获取unionid
      headimgurl: '', // 通过后台交换code获取 headimgurl
      nickname: '', // 通过后台交换code获取 nickname
      sex: '', // 通过后台交换code获取 sex
      aloading: false
    }
  },
  created() {
    this.getOpenIdFunc()
  },
  methods: {
    getOpenIdFunc() {
      this.aloading = true
      getSnsUserInfo({ code: this.auth_code }).then(res => {
        if (res.errNo == 0) {
          const result = res.result || {}
          if (result.openid) {
            this.openid = result.openid || ''
            this.unionid = result.unionid || ''
            this.nickname = result.nickname || ''
            this.headimgurl = result.headimgurl || ''
            this.sex = result.sex || 0
            // 不绑定，存储信息
            localStorage.setItem('auth_openid', this.openid)
            localStorage.setItem('auth_unionid', this.unionid)
            localStorage.setItem('auth_nickname', this.nickname)
            localStorage.setItem('auth_headimgurl', this.headimgurl)
            localStorage.setItem('auth_state', 1)
            // 检测是否将授权信息放到回调链接
            this.checkRedirectUrlForAuth()
            this.goBack()
            // this.bindUserWechat()
          } else {
            this.$message.error('授权失败')
            this.goBack()
          }
        } else {
          this.$message.error('授权失败')
          this.goBack()
        }
        this.aloading = false
      }).catch(() => {
        this.aloading = false
      })
    },
    // 绑定微信
    bindUserWechat() {
      const query = {
        openid: this.openid,
        unionid: this.unionid,
        nickname: this.nickname,
        headimgurl: this.headimgurl,
        sex: this.sex
      }
      InsetBind(query).then(res => {
        if (res.errNo == 0) {
          this.$message.success('绑定成功')
          this.forGoBack()
        } else {
          this.$message.error(res.msg || '绑定失败~')
          if (res.errNo == 2001) {
            // 需要重新登录下
            this.showBindLogin()
          } else {
            this.forGoBack()
          }
        }
      })
    },
    // 再加一层处理回调
    forGoBack() {
      setTimeout(() => {
        this.goBack()
      }, 1200)
    },
    // 回调
    goBack() {
      if (this.redirect_url && this.redirect_url.length > 0) {
        window.location.href = this.redirect_url
      } else {
        this.$router.go(-1)
      }
    },
    // 去登录，传参openid、unionid等
    showBindLogin() {
      const url = `${window.location.origin}/ucenter/other_login?type=2&redirect=${this.redirect_url}&openid=${this.openid}&unionid=${this.unionid}&nickname=${this.nickname}`
      window.location.href = url
    },
    // 检测是否将授权信息放到回调链接：针对指定域名的处理：sm2.1py.com.cn、m.zhulongwang.cn
    checkRedirectUrlForAuth() {
      // 1、判断redirect_url是否存在
      // 2、判断redirect_url是否已有参数：auth_openid、auth_unionid、auth_nickname、auth_state
      // 3、若有旧值，替换
      // 4、拼接新值
      const redirect_url = decodeURIComponent(this.redirect_url)
      if (redirect_url && redirect_url.length > 0) {
        // 判断指定域名
        if (redirect_url.includes('sm2.1py.com') || redirect_url.includes('m.zhulongwang.cn')) {
          var new_redirect_url = redirect_url
          new_redirect_url = this.redplaceParamsValue(new_redirect_url, 'auth_openid', this.openid)
          new_redirect_url = this.redplaceParamsValue(new_redirect_url, 'auth_unionid', this.unionid)
          new_redirect_url = this.redplaceParamsValue(new_redirect_url, 'auth_nickname', this.nickname)
          new_redirect_url = this.redplaceParamsValue(new_redirect_url, 'auth_headimgurl', this.headimgurl)
          new_redirect_url = this.redplaceParamsValue(new_redirect_url, 'auth_state', 1)
          this.redirect_url = new_redirect_url
        }
      }
    },
    // 替换链接中的参数值
    redplaceParamsValue(url, param, newValue) {
      // 创建一个 URL 对象
      const urlObj = new URL(url)
      // 获取查询参数
      const params = new URLSearchParams(urlObj.search)
      // 检查是否已存在指定参数
      if (params.has(param)) {
        // 如果存在，则替换其值
        params.set(param, newValue)
      } else {
        // 如果不存在，则添加新的参数
        params.append(param, newValue)
      }
      // 更新 URL 的查询字符串部分
      urlObj.search = params.toString()
      // 返回更新后的 URL 字符串
      return urlObj.toString()
    }
  }
}
</script>

<style scoped lang="scss">
.contnet {
  // width: 700px;
  margin: 0 auto;
}
.authBtn {
  margin: 30px 10% 0;
  padding: 20px;
  word-break: break-all;
  text-align: left;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  background-color: #ee2e2e;
}
.doneBtn {
  margin: 60px 10% 0;
  padding: 10px;
  word-break: break-all;
  text-align: center;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  background-color: #ee2e2e;
}
</style>
